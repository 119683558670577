var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Animations"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeAnimation) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v("popup")]), _c('span', [_vm._v(" inside ")]), _c('code', [_vm._v("showClass")]), _c('span', [_vm._v(" parameter to add animation to your alert.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.bounceIn
    }
  }, [_vm._v(" Bounce In ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.fadeIn
    }
  }, [_vm._v(" Fade In ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.flipIn
    }
  }, [_vm._v(" Flip In ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.tada
    }
  }, [_vm._v(" Tada ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.shake
    }
  }, [_vm._v(" Shake ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }