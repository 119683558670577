var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Confirm Options"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeConfirm) + " ")];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('div', [_c('h5', [_vm._v("Confirm Button Text")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.confirmText
    }
  }, [_vm._v(" Confirm Text ")])], 1), _c('div', [_c('h5', [_vm._v("Confirm Button Text")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.confirmButtonColor
    }
  }, [_vm._v(" Confirm Button Color ")])], 1)])]);
}
var staticRenderFns = []

export { render, staticRenderFns }