var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Types"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeTypes) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_vm._v(" The type of the modal. SweetAlert comes with 4 built-in types which will show a corresponding icon animation: \"success\", \"error\", \"warning\" and \"info\". You can also set it as \"input\" to get a prompt modal. It can either be put in the object under the key \"icon\" or passed as the third parameter of the function. ")]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-success"
    },
    on: {
      "click": _vm.success
    }
  }, [_vm._v(" Success ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(234, 84, 85, 0.15)',
      expression: "'rgba(234, 84, 85, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": _vm.error
    }
  }, [_vm._v(" Error ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 159, 67, 0.15)',
      expression: "'rgba(255, 159, 67, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-warning"
    },
    on: {
      "click": _vm.warning
    }
  }, [_vm._v(" Warning ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(0, 207, 232, 0.15)',
      expression: "'rgba(0, 207, 232, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-info"
    },
    on: {
      "click": _vm.info
    }
  }, [_vm._v(" Info ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }